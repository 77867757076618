<template>
    <ValidationObserver rules="required">
      <RightSideBar @close="$emit('close','regroup')" button-class="bg-dynamicBackBtn text-white" :submit="'Save'" :cancel="'Cancel'" :loading="isLoading" @submit="submit" :disabled-button="!group">
        <template v-slot:title>
          <div class="text-darkPurple flex gap-5 items-center">
            <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> Re-Group
          </div>
        </template>
        <div class="flex flex-col gap-5">
            <div class="flex flex-col justify-center">
                <span class="text-sm mb-2 text-jet font-bold">Folder Name</span>
                <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center flex gap-2 text-base">
                    <span>{{item.name}}</span>
                </div>
            </div>

            <div class="w-full flex flex-col gap-1" v-if="currentTab.toLowerCase().includes('folders')">
              <GroupToolTip :isGroupToolTip="isGroupToolTip"
                @on-enter="isGroupToolTip = true"
                @on-leave="isGroupToolTip = false"
              />
              <c-select
                placeholder="--Select--"
                :options="filteredGroups || []"
                variant="w-full"
                :rules="['required']"
                v-model="group"
              />
            </div>
        </div>
      </RightSideBar>
    </ValidationObserver>
  </template>

  <script>
  import { ValidationObserver } from "vee-validate";
  import RightSideBar from "../RightSideBar";
  import CSelect from "@scelloo/cloudenly-ui/src/components/select"

  export default {
    name: "Regroup",
    components: {
      ValidationObserver,
      RightSideBar,
      CSelect,
      GroupToolTip: () => import("./groupActionsModel/GroupToolTip")
    },
    props: {
        item: {
        type: Object,
        default: () => ({})
      },
        currentTab: {
        type: String,
        default: ''
      },
      groups: {
        type: Array,
        default: () => ([])
      },
    },
    data() {
      return {
        isLoading: false,
        isOpen: true,
        group:'',
        isGroupToolTip: false,
      };
    },
    methods: {
        closeSidebar() {
        this.$emit('close','regroup');
        },
        async submit() {
            try {
                this.isLoading = true;
                const payload = {
                    "name": this.item.name,
                    "creatorUserId": this.item.createdBy.id,
                    "groupId": this.group,
                    "orgId": this.$orgId
                }
                if (this.currentTab.toLowerCase().includes('folders')) {
                    await this.$_editOrgFolder({folderId : this.item.folderId,payload});
                } else {
                    await this.$_editOrgFile({fileId : this.item.fileId,payload});
                }
                this.$emit('refresh');
                this.closeSidebar();
                          return this.$toasted.success(`Folder regruoped successfully.`,{duration: 6000});
            } catch (error) {
              return this.$toasted.error(`Error regrouping.`,{duration: 6000});
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        filteredGroups() {
            const groups = this.groups.filter(group => group.id !== this.item.group.id);
            return groups
        },
    }
  };
  </script>

  <style scoped>
      .payInfo_fakeInput{
      background-color: #EEEEEE;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border: 1px solid #878E99;
      border-radius: 5px;
    }
  </style>
